<template>
  <div v-if="isLoading"
       class="center-vertical center-horizontal">
    <v-progress-circular
      indeterminate
      color="info"
      size="80"
      width="5"/>
  </div>
  <div v-else-if="!loadedUserGroups"
       class="center-vertical">
      <div class="center-horizontal">
        <h1> {{$t('errors.no-data-available')}} </h1>
      </div>
      <div class="center-horizontal">
        <v-btn fab
               icon
               @click="loadPage"
               color="primary"
               x-large>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </div>
  </div>
  <div v-else
       class="d-flex flex-wrap justify-start-between">
    <SystemCard
      v-for="systemId in systemIds"
      :key="systemId"
      :systemId="systemId"
      :isOnline="isSystemOnline(systemId)"
      :status="getStatusText(systemId)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import SystemCard from '@/components/SystemCard'
import SystemHub from '@/models/systemHub'

export default {
  components: { SystemCard },
  data () {
    return {
      systemHub: null,
      systemStatuses: [],
      isLoading: true,
      loadedUserGroups: false
    }
  },
  computed: {
    ...mapState('account', ['authorizedUserGroups', 'authenticationToken']),
    ...mapGetters('account', ['requiresRefresh']),
    systemIds () {
      // Grabs the system ids from all authorized user groups and flattens them to a single array of ids.
      const systemIds = this.authorizedUserGroups.map(ug => ug.systemIds).flat().sort()
      // The flattened array may contain duplicates, so we have to make it only contain unique ids.
      return [...new Set(systemIds)]
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('account', ['fetchMyUserGroups', 'refreshLogin']),
    async connectToSystemHub () {
      this.systemHub = new SystemHub(this.getAccessTokenForSystemHub)

      this.systemHub.connection.onclose(this.systemHubClosed)
      this.systemHub.connection.onreconnecting(this.systemHubReconnecting)
      this.systemHub.connection.onreconnected(this.systemHubReconnected)
      this.systemHub.connection.on('NewStatusReceived', this.newStatusReceived)
      this.systemHub.connection.on('AllStatusesReceived', this.allStatusesReceived)
      this.systemHub.connection.on('NewEventsReceived', () => { /* Intentionally empty, we don't need this data in this page yet. */ })

      await this.systemHub.start()
      // We need to subscribe to the specific systems in order to get updates on them.
      const immediatelySendAllStatuses = true
      await this.systemHub.subscribe(this.systemIds, immediatelySendAllStatuses)
    },
    newStatusReceived (data) {
      const existingIndex = this.systemStatuses.findIndex(status => status.systemId === data.systemId)
      if (existingIndex === -1) {
        this.systemStatuses.push(data)
      } else {
        // $set is a special method to allow vue to react to array changes.
        // See https://vuejs.org/v2/guide/reactivity.html for more info.
        this.$set(this.systemStatuses, existingIndex, data)
      }
    },
    allStatusesReceived (data) {
      this.systemStatuses = data
    },
    systemHubReconnecting (e) {
      this.setStatusesToUnknown()
    },
    async systemHubReconnected (e) {
      // We need to renew the subscriptions on reconnect,
      // because we don't know if the server still remembers what we were subscribed to before the connection was gone.
      const immediatelySendAllStatuses = true
      await this.systemHub.subscribe(this.systemIds, immediatelySendAllStatuses)
    },
    systemHubClosed (e) {
      this.setStatusesToUnknown()
    },
    isSystemOnline (systemId) {
      const status = this.systemStatuses.find(status => status.systemId === systemId)
      return status?.isOnline
    },
    getStatusText (systemId) {
      const status = this.systemStatuses.find(status => status.systemId === systemId)
      return status?.status
    },
    setStatusesToUnknown () {
      this.systemStatuses = this.systemIds.map(systemId => ({
        systemId: systemId,
        status: null,
        isOnline: null
      }))
    },
    async getAccessTokenForSystemHub () {
      // Refresh the authentication token if required.
      // The refresh is placed inside this factory in accordance with:
      // https://docs.microsoft.com/en-us/aspnet/core/signalr/authn-and-authz?view=aspnetcore-6.0#bearer-token-authentication.
      if (this.requiresRefresh()) await this.refreshLogin()

      return this.authenticationToken
    },
    async loadUserGroups () {
      this.isLoading = true
      try {
        await this.fetchMyUserGroups()
        this.loadedUserGroups = true
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
        this.loadedUserGroups = false
      } finally {
        this.isLoading = false
      }
    },
    async loadPage () {
      await this.loadUserGroups()
      if (!this.loadedUserGroups) return

      this.setStatusesToUnknown()
      try {
        await this.connectToSystemHub()
      } catch (error) {
        // We don't have to show errors to the user here;
        // the gray online icons in the system cards will already indicate that there is no live data.
        console.error(error)
      }
    }
  },
  async mounted () {
    await this.loadPage()
  },
  async beforeDestroy () {
    if (!this.systemHub) return
    await this.systemHub.stop()
  }
}
</script>

<style lang="scss" scoped>
.center-vertical {
  margin-top:40vh;
}

.center-horizontal {
  display: flex;
  justify-content: center;
}
</style>
