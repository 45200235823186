import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

export default class SystemHub {
  /**
   * Creates a SystemHub instance.
   * You can get the SignalR HubConnection directly from the 'connection' property after using this constructor.
   * @param {Function} accessTokenFactory The method to retrieve an access token to use for authenticating to the hub.
   */
  constructor (accessTokenFactory) {
    const url = `${process.env.VUE_APP_API_URL}/hubs/systems`
    const logLevel = process.env.VUE_APP_DEV_MODE === 'true' ? LogLevel.Information : LogLevel.Warning
    const reconnectStrategy = {
      // Retry connecting every 4-6 seconds until 30 seconds have passed, then wait 25-30 seconds between retries.
      nextRetryDelayInMilliseconds: retryContext => {
        if (retryContext.elapsedMilliseconds < 30) return Math.random() * 2000 + 4000 // 4-6 seconds.
        else return Math.random() * 5000 + 25000 // 25-30 seconds.
      }
    }

    this.connection = new HubConnectionBuilder()
      .withUrl(url, { accessTokenFactory: accessTokenFactory })
      .withAutomaticReconnect(reconnectStrategy)
      .configureLogging(logLevel)
      .build()
  }

  async subscribe (systemIds, immediatelySendAllStatuses) {
    await this.connection.invoke('Subscribe', {
      SystemIds: systemIds,
      ImmediatelySendAllStatuses: immediatelySendAllStatuses
    })
  }

  async start () {
    if (this.started) {
      console.warn('SystemHub was already started')
      return
    }
    this.started = true
    console.debug('Starting SystemHub')

    try {
      await this.connection.start()
    } catch (error) {
      // Resetting the 'started' boolean if the connection failed to start.
      this.started = false
      throw error
    }
  }

  async stop () {
    if (!this.started) {
      console.warn('SystemHub was already stopped')
      return
    }

    console.debug('Stopping SystemHub')
    this.started = false
    await this.connection.stop()
  }
}
