<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    hide-overlay
    scrollable
  >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
            color="info"
            dark
          >
            <v-btn
              icon
              dark
              @click="showDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('sessions.details-dialog.title') }}</v-toolbar-title>

          </v-toolbar>
        </v-card-title>

        <!-- We have to put the flex properties on the v-card-text, because otherwise it won't center. -->
        <v-card-text
          v-if="isLoading"
          class="d-flex justify-center align-center"
        >
          <v-progress-circular
            indeterminate
            color="info"
            size="80"
            width="5"
          />
        </v-card-text>

        <v-card-text v-else>
          <v-row>
            <v-col cols="12" sm="12" md="8">
              <v-timeline dense align-top>
                <EventTimeLineItem
                  v-for="event in events"
                  :key="event.id"
                  :event="event"
                />
              </v-timeline>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="pt-6">
              <!-- Vehicle -->
              <label class="caption font-italic">
                {{ $t('sessions.details-dialog.vehicle-label') }}
              </label>
              <p>
                <a class="body-1" :href="vehicleHref" v-if="vehicle">
                  {{ vehicle.name || vehicle.uniqueIdentifier }}
                </a>
                <span v-else>-</span>
              </p>

              <!-- Error cause -->
              <label class="caption font-italic">
                {{ $t('sessions.details-dialog.error-cause-label') }}
              </label>
              <p class="body-1">
                {{ errorCause ? errorCause.description : '-' }}
              </p>

              <!-- Comment -->
              <label class="caption font-italic">
                {{ $t('sessions.details-dialog.comment-label') }}
              </label>
              <p class="body-1">
                {{ session.comment }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import EventTimeLineItem from '@/components/EventTimeLineItem'

export default {
  components: { EventTimeLineItem },
  props: {
    /**
     * Indicates whether this dialog should be shown right now.
     * Use the @input event to listen for the dialog to be closed.
     */
    value: { type: Boolean, required: true },
    session: { type: Object, default: null }
  },
  data () {
    return {
      events: [],
      isLoading: false,
      vehicle: null,
      errorCause: null
    }
  },
  computed: {
    ...mapGetters('errorCauses', ['getErrorCauseById']),
    ...mapGetters('vehicles', ['getVehicleById']),
    showDialog: {
      get: function () { return this.value },
      set: function (newValue) { this.$emit('input', newValue) }
    },
    vehicleHref () {
      return `/vehicle/${this.session.vehicleId}`
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    async fetchEvents () {
      const response = await axios.get(`sessions/${this.session.id}/systemEvents`)
      this.events = response.data
    },
    async fetchErrorCause () {
      // First check if the error cause exists in the store.
      this.errorCause = this.getErrorCauseById(this.session.errorCauseId)
      if (this.errorCause) return

      // If it does not exist in the store, try to fetch it from the API.
      const response = await axios.get(`errorcauses/${this.session.errorCauseId}`)
      this.errorCause = response.data
    },
    async fetchVehicle () {
      // First check if the vehicle exists in the store.
      this.vehicle = this.getVehicleById(this.session.vehicleId)
      if (this.vehicle) return

      // If it does not exist in the store, try to fetch it from the API.
      const response = await axios.get(`vehicles/${this.session.vehicleId}`)
      this.vehicle = response.data
    },
    /**
     * Fetches all data required for this dialog.
     */
    async fetchData () {
      try {
        this.isLoading = true
        await Promise.all([
          this.fetchEvents(),
          this.fetchVehicle(),
          this.session.errorCauseId ? this.fetchErrorCause() : Promise.resolve()
        ])
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    // Watch for value (v-model) changes. Occurs when the dialog is opened/closed.
    value: {
      immediate: true,
      async handler (newVal, oldVal) {
        // Reset data.
        this.events = []
        this.vehicle = null
        this.errorCause = null

        if (newVal) {
          // Fetches all required data when the dialog opens.
          await this.fetchData()
        }
      }
    }
  }
}
</script>
