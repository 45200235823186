<template>
  <v-row>
    <v-col cols="12">
      <!-- Create dialog -->
      <AddEditFileDialog
        v-if="canCreate"
        v-model="showCreateNewDialog"
        @input="(newValue) => { showCreateNewDialog = newValue }"
        @itemCreated="onItemCreated"
      />

      <!-- Edit dialog -->
      <AddEditFileDialog
        v-if="canEdit"
        v-model="showEditDialog"
        :file="itemToEdit"
        @input="(newValue) => { showEditDialog = newValue }"
        @itemUpdated="onItemUpdated"
      />

      <!-- Delete dialog -->
      <DeleteItemsDialog
        v-if="canDelete"
        v-model="showDeleteDialog"
        @input="(newValue) => { showDeleteDialog = newValue }"
        :warning="$t('files.delete-warning')"
        :items="selectedItems"
        deleteUrl="files"
        :entityName="$t('common.file').toLowerCase()"
        :entityNamePlural="$t('common.files').toLowerCase()"
        @itemDeleted="onItemDeleted"
      />

      <!-- Projects table -->
      <v-data-table
        v-model="selectedItems"
        :items="items"
        :server-items-length="totalSize"
        :options.sync="options"
        :multi-sort="true"
        :headers="computedHeaders"
        :loading="loading"
        :features="features"
        :show-select="canDelete"
        disable-pagination
        hide-default-footer
        class="elevation-2"
        :id="tableId"
      >
        <!-- Title & Actions -->
        <template v-if="title != null || canCreate" v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title v-if="title != null">{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>

            <!-- Delete button -->
            <v-btn
              v-if="canDelete"
              color="error"
              @click="deleteClicked"
              class="mx-1"
              :disabled="selectedItems.length === 0"
            >
              <v-icon :left="$vuetify.breakpoint.mdAndUp">
                mdi-delete-forever
              </v-icon>
              <div v-if="$vuetify.breakpoint.mdAndUp">
                {{ $t('common.buttons.delete') }}
              </div>
            </v-btn>

            <!-- Create button -->
            <v-btn
              v-if="canCreate"
              color="primary"
              @click="createNewClicked"
              class="mx-1"
            >
              <v-icon :left="$vuetify.breakpoint.mdAndUp">
                mdi-plus-thick
              </v-icon>
              <div v-if="$vuetify.breakpoint.mdAndUp">
                {{ $t('common.buttons.create') }}
              </div>
            </v-btn>
          </v-toolbar>
        </template>

        <!-- Empty display -->
        <template v-slot:no-data>
          <h3 class="mt-3"> {{$t('errors.no-data-available')}} </h3>
          <v-btn
            icon
            large
            class="my-2"
            color="primary"
            @click="$emit('refreshRequested')"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>

        <!-- Custom cells -->
        <template v-slot:[`item.fileContainerId`]="{value}">
          <FileContainerDisplay :fileContainerId="value"/>
        </template>

        <!-- Custom actions -->
        <template v-slot:[`item.actions`]="{item}">
          <!-- Edit button -->
          <v-btn
            v-if="canEdit"
            icon
            @click="editClicked(item)"
            color="orange">
            <v-icon>
              mdi-square-edit-outline
            </v-icon>
          </v-btn>

          <!-- Download button -->
          <v-btn
            v-if="canDownload"
            :disabled="currentlyDownloadingFileId != null"
            :loading="currentlyDownloadingFileId == item.id"
            icon
            @click="downloadClicked(item)"
            color="info">
            <v-icon>
              mdi-download
            </v-icon>
          </v-btn>
        </template>

      </v-data-table>
    </v-col>

    <!-- Pagination -->
    <v-col cols="12" v-if="usePaging && totalSize > 0">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      />
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import AddEditFileDialog from '@/components/dialogs/AddEditFileDialog'
import DeleteItemsDialog from '@/components/dialogs/DeleteItemsDialog'
import FileContainerDisplay from '@/components/FileContainerDisplay'
import Sorting from '@/models/sorting'

export default {
  components: { AddEditFileDialog, DeleteItemsDialog, FileContainerDisplay },
  emits: ['pageChanged', 'sortingChanged', 'refreshRequested', 'itemDeleted', 'itemCreated', 'itemUpdated'],
  props: {
    /**
     * The items to display.
     */
    items: { type: Array, required: true },
    /**
     * The C(R)UD features to enable for this table.
     * Supply with an array of the desired features ('create', 'update', 'delete').
     */
    features: { type: Array, default: () => ['create', 'update', 'delete'] },
    title: { type: String, default: null },
    totalSize: { type: Number, default: 0 },
    /**
     * The headers and columns to display.
     */
    headers: { type: Array },
    /**
     * True will enable pagination buttons/inputs, false will disable them. Defaults to true.
     */
    usePaging: { type: Boolean, default: true },
    pageSize: { type: Number, default: null },
    pageNumber: { type: Number, default: null },
    loading: { type: Boolean, default: false }
  },
  data () {
    return {
      currentPage: 0,
      showCreateNewDialog: false,
      showEditDialog: false,
      showDeleteDialog: false,
      itemToEdit: {},
      selectedItems: [],
      isDeletingItems: false,
      tableId: 'files-table',
      scrollOptions: {
        easing: 'easeInOutQuad',
        duration: 300,
        offset: 0
      },
      options: {},
      currentlyDownloadingFileId: null
    }
  },
  computed: {
    totalPages () {
      return this.totalSize === 0 || this.pageSize === 0 ? 0 : Math.ceil(this.totalSize / this.pageSize)
    },
    canCreate () {
      return this.features && this.features.includes('create')
    },
    canEdit () {
      return this.features && this.features.includes('update')
    },
    canDelete () {
      return this.features && this.features.includes('delete')
    },
    canDownload () {
      return this.features && this.features.includes('download')
    },
    computedHeaders () {
      // Both the injected (prop) and default headers must be wrapped in a computed
      // to ensure translations don't require a page refresh.
      return this.headers && this.headers.length > 0 ? this.headers : this.defaultHeaders
    },
    defaultHeaders () {
      return [
        { text: this.$t('files.table.columns.name'), value: 'name' },
        { text: this.$t('files.table.columns.extension'), value: 'extension' },
        { text: this.$t('files.table.columns.file-container'), value: 'fileContainerId' },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ]
    }
  },
  methods: {
    editClicked (item) {
      this.itemToEdit = item
      this.showEditDialog = true
    },
    async downloadClicked (item) {
      this.currentlyDownloadingFileId = item.id
      try {
        const response = await axios.get(`files/${item.id}/downloadlink`)
        const downloadUrl = response.data

        // Creates an invisible link an programmatically clicks it to start the download.
        const a = document.createElement('a')
        a.href = downloadUrl
        document.body.appendChild(a)
        a.click()
        setTimeout(function () {
          document.body.removeChild(a)
        }, 0)
      } finally {
        this.currentlyDownloadingFileId = null
      }
    },
    createNewClicked () {
      this.showCreateNewDialog = true
    },
    deleteClicked () {
      this.showDeleteDialog = true
    },
    handlePageChange (value) {
      this.$emit('pageChanged', value)
      // Scroll back to top.
      this.$vuetify.goTo(`#${this.tableId}`, this.scrollOptions)
    },
    onItemDeleted (deletedItem) {
      // Try to find the deleted item among the currently selected items.
      const index = this.selectedItems.findIndex((item) => item.id === deletedItem.id)
      if (index !== -1) {
        // Delete the item from the selected items.
        this.selectedItems.splice(index, 1)
      }
      this.$emit('itemDeleted', deletedItem)
    },
    onItemUpdated (item) {
      this.$emit('itemUpdated', item)
    },
    onItemCreated (item) {
      this.$emit('itemCreated', item)
    }
  },
  mounted () {
    this.currentPage = this.pageNumber
  },
  watch: {
    totalSize (newValue, oldValue) {
      if (this.currentPage > this.totalPages && this.currentPage !== 1) {
        console.warn(`Page ${this.currentPage} does not exist, routing back to page 1`)
        this.currentPage = 1
        this.$emit('pageChanged', this.currentPage)
      }
    },
    options: {
      immediate: false,
      handler (newValue) {
        this.$emit('sortingChanged', new Sorting(this.options.sortBy, this.options.sortDesc))
      }
    }
  }
}
</script>
