<template v-if="event">
  <v-timeline-item
    :color="color"
    class="py-1"
  >
    <v-hover v-slot="{ hover }">
      <div class="d-flex">
        <!-- Time -->
        <DateTimeDisplay
          :date="event.timeStamp"
          :format="timestampFormat"
          class="time"
        />

        <v-card
          :elevation="hover ? 10 : 6"
          class="event-card"
        >
          <!-- Name -->
          <v-card-title
            class="text-subtitle-1 font-weight-medium py-1">
            {{ event.value }}
          </v-card-title>

          <!-- Event data -->
          <v-card-text v-if="hasEventData">
            <v-skeleton-loader
              :loading="isLoading"
              :type="skeletonLoaderType"
            >
              <SimpleEventDataTable
                :eventData="eventData"
                :loading="isLoading"
              />
            </v-skeleton-loader>
          </v-card-text>
        </v-card>
      </div>
    </v-hover>
  </v-timeline-item>
</template>

<script>
import axios from 'axios'
import DateTimeDisplay from '@/components/DateTimeDisplay'
import SimpleEventDataTable from '@/components/tables/SimpleEventDataTable'

export default {
  components: { DateTimeDisplay, SimpleEventDataTable },
  props: {
    event: { type: Object, default: null },
    showDate: { type: Boolean, default: false }
  },
  data () {
    return {
      isLoading: false,
      eventData: []
    }
  },
  computed: {
    color () {
      switch (this.event.eventType) {
        case 'warning':
          return 'warning'
        case 'error':
          return 'error'
        case 'recovery-error':
          return '#B71C1C' // Same as classes 'red darken-4'.
        case 'status':
        default:
          return 'info'
      }
    },
    hasEventData () {
      return this.event.eventDataIds.length > 0
    },
    skeletonLoaderType () {
      return new Array(this.event.eventDataIds.length)
        .fill('table-cell')
        .join(', divider, ')
    },
    timestampFormat () {
      return this.showDate ? 'YYYY-MM-DD | HH:mm:ss' : 'HH:mm:ss'
    }
  },
  methods: {
    async fetchEventData () {
      this.isLoading = true
      try {
        const response = await axios.get(`systemEvents/${this.event.id}/systemEventData`)
        this.eventData = response.data
      } finally {
        this.isLoading = false
      }
    }
  },
  async mounted () {
    if (this.hasEventData) {
      await this.fetchEventData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.time {
  padding-top: 10px;
  padding-right: 5%;
  white-space: nowrap;
}

.event-card {
  overflow:auto;
  // On extra large devices...
  @media #{map-get($display-breakpoints, 'xl-only')} {
    min-width: 500px;
  }
  // On large devices...
  @media #{map-get($display-breakpoints, 'lg-and-down')} {
    min-width: 270px;
  }
  // On medium devices...
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    min-width: 270px;
  }
  // On small devices...
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    min-width: 150px;
  }

  &:hover {
    margin-left: -0.5%;
  }
}
</style>
