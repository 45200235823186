<template>
  <v-row>
    <v-col cols="12">
      <!-- Create dialog -->
      <AddEditUserDialog
        v-if="canCreate"
        v-model="showCreateNewDialog"
        @input="(newValue) => { showCreateNewDialog = newValue }"
        @itemCreated="onItemCreated"
      />

      <!-- Edit dialog -->
      <AddEditUserDialog
        v-if="canEdit"
        v-model="showEditDialog"
        :user="itemToEdit"
        @input="(newValue) => { showEditDialog = newValue }"
        @itemUpdated="onItemUpdated"
      />

      <!-- Delete dialog -->
      <DeleteItemsDialog
        v-if="canDelete"
        v-model="showDeleteDialog"
        @input="(newValue) => { showDeleteDialog = newValue }"
        :items="selectedItems"
        deleteUrl="users"
        :entityName="$t('common.user').toLowerCase()"
        :entityNamePlural="$t('common.users').toLowerCase()"
        @itemDeleted="onItemDeleted"
      />

      <!-- Suspend dialog -->
      <AddEditSuspensionsDialog
        v-if="canSuspend"
        v-model="showSuspendDialog"
        @input="(newValue) => { showSuspendDialog = newValue }"
        :title="$t('users.suspension-dialog.title')"
        :warning="$t('users.suspension-dialog.warning')"
        @confirmed="onSuspensionConfirmed"
        :isBusy="isBusySuspending"
        :validationErrors="suspensionValidationErrors"
      />

      <!-- Users table -->
      <v-data-table
        v-model="selectedItems"
        :items="items"
        :server-items-length="totalSize"
        :options.sync="options"
        :multi-sort="true"
        :headers="computedHeaders"
        :loading="loading"
        :features="features"
        :show-select="canDelete || canSuspend"
        :show-expand="canSuspend"
        single-expand
        disable-pagination
        hide-default-footer
        class="elevation-2"
        :id="tableId"
      >
        <!-- Title & Actions -->
        <template v-if="title != null || canCreate || canDelete || canSuspend" v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title v-if="title != null">{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>

            <!-- Suspend button -->
            <v-btn
              v-if="canSuspend"
              @click="showSuspendDialog = true"
              :disabled="selectedItems.length === 0"
              color="orange darken-2"
              class="mx-1 white--text"
            >
              <v-icon :left="$vuetify.breakpoint.mdAndUp">
                mdi-lock
              </v-icon>
              <div v-if="$vuetify.breakpoint.mdAndUp">
                {{ $t('users.table.suspend-button') }}
              </div>
            </v-btn>

            <!-- Delete button -->
            <v-btn
              v-if="canDelete"
              @click="showDeleteDialog = true"
              :disabled="selectedItems.length === 0"
              color="error"
              class="mx-1 white--text"
            >
              <v-icon :left="$vuetify.breakpoint.mdAndUp">
                mdi-delete-forever
              </v-icon>
              <div v-if="$vuetify.breakpoint.mdAndUp">
                {{ $t('common.buttons.delete') }}
              </div>
            </v-btn>

            <!-- Create button -->
            <v-btn
              v-if="canCreate"
              @click="showCreateNewDialog = true"
              color="primary"
              class="mx-1 white--text"
            >
              <v-icon :left="$vuetify.breakpoint.mdAndUp">
                mdi-plus-thick
              </v-icon>
              <div v-if="$vuetify.breakpoint.mdAndUp">
                {{ $t('common.buttons.create') }}
              </div>
            </v-btn>
          </v-toolbar>
        </template>

        <!-- Empty display -->
        <template v-slot:no-data>
          <h3 class="mt-3"> {{$t('errors.no-data-available')}} </h3>
          <v-btn
            icon
            large
            class="my-2"
            color="primary"
            @click="$emit('refreshRequested')"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>

        <!-- Custom cells -->
        <template v-slot:[`item.indications`]="{item}">
          <v-tooltip right v-if="item.isSuspended">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="orange darken-2"
                class="tooltip-icon"
                dense
                v-bind="attrs"
                v-on="on"
              >
                mdi-account-lock
              </v-icon>
            </template>
            <span>{{ $t('users.table.suspended-tooltip') }}</span>
          </v-tooltip>
          <v-tooltip right v-if="!item.emailConfirmed && !item.hasValidPendingRegistration">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="red darken-2"
                class="tooltip-icon"
                dense
                v-bind="attrs"
                v-on="on"
              >
                mdi-email-remove
              </v-icon>
            </template>
            <span>{{ $t('users.table.no-valid-pending-registration-tooltip') }}</span>
          </v-tooltip>
          <v-tooltip right v-else-if="!item.emailConfirmed && item.hasValidPendingRegistration">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="info darken-1"
                class="tooltip-icon"
                dense
                v-bind="attrs"
                v-on="on"
              >
                mdi-email-alert
              </v-icon>
            </template>
            <span>{{ $t('users.table.email-address-not-confirmed-tooltip') }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.userGroups`]="{item}">
          {{item.userGroupIds.length}}
        </template>
        <template v-slot:[`item.roles`]="{item}">
          <RolesDisplay :roleIds="item.roleIds"/>
        </template>

        <!-- Expanded -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0">
            <UserTableExpansion
              :user="item"
              @registrationResent="onRegistrationResent"
            />
          </td>
        </template>

        <!-- Custom actions -->
        <template v-slot:[`item.actions`]="{item}">
          <v-btn
            v-if="canEdit"
            icon
            @click="editClicked(item)"
            color="orange">
            <v-icon>
              mdi-square-edit-outline
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>

    <!-- Pagination -->
    <v-col cols="12" v-if="usePaging && totalSize > 0">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import AddEditSuspensionsDialog from '@/components/dialogs/AddEditSuspensionsDialog'
import AddEditUserDialog from '@/components/dialogs/AddEditUserDialog'
import DeleteItemsDialog from '@/components/dialogs/DeleteItemsDialog'
import RolesDisplay from '@/components/RolesDisplay'
import Sorting from '@/models/sorting'
import UserTableExpansion from '@/components/tables/UserTableExpansion'

export default {
  components: { AddEditSuspensionsDialog, AddEditUserDialog, DeleteItemsDialog, RolesDisplay, UserTableExpansion },
  emits: ['pageChanged', 'sortingChanged', 'refreshRequested', 'itemDeleted', 'itemCreated', 'itemUpdated', 'registrationResent'],
  props: {
    /**
     * The items to display.
     */
    items: { type: Array, required: true },
    /**
     * The C(R)UD features to enable for this table.
     * Supply with an array of the desired features ('create', 'update', 'delete', 'suspend').
     */
    features: { type: Array, default: () => ['create', 'update', 'delete', 'suspend'] },
    title: { type: String, default: null },
    totalSize: { type: Number, default: 0 },
    /**
     * The headers and columns to display.
     */
    headers: { type: Array },
    /**
     * True will enable pagination buttons/inputs, false will disable them. Defaults to true.
     */
    usePaging: { type: Boolean, default: true },
    pageSize: { type: Number, default: null },
    pageNumber: { type: Number, default: null },
    loading: { type: Boolean, default: false }
  },
  data () {
    return {
      currentPage: 0,
      showCreateNewDialog: false,
      showEditDialog: false,
      showDeleteDialog: false,
      showSuspendDialog: false,
      isBusySuspending: false,
      itemToEdit: {},
      selectedItems: [],
      isDeletingItems: false,
      tableId: 'users-table',
      scrollOptions: {
        easing: 'easeInOutQuad',
        duration: 300,
        offset: 0
      },
      suspensionValidationErrors: {},
      options: {}
    }
  },
  computed: {
    totalPages () {
      return this.totalSize === 0 || this.pageSize === 0 ? 0 : Math.ceil(this.totalSize / this.pageSize)
    },
    canCreate () {
      return this.features && this.features.includes('create')
    },
    canEdit () {
      return this.features && this.features.includes('update')
    },
    canDelete () {
      return this.features && this.features.includes('delete')
    },
    canSuspend () {
      return this.features && this.features.includes('suspend')
    },
    computedHeaders () {
      // Both the injected (prop) and default headers must be wrapped in a computed
      // to ensure translations don't require a page refresh.
      return this.headers && this.headers.length > 0 ? this.headers : this.defaultHeaders
    },
    defaultHeaders () {
      return [
        { text: '', value: 'indications', sortable: false },
        { text: this.$t('users.table.columns.email-address'), value: 'emailAddress' },
        { text: this.$t('users.table.columns.usergroups'), value: 'userGroups' },
        { text: this.$t('users.table.columns.roles'), value: 'roles' },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ]
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('suspensions', ['createSuspension']),
    editClicked (item) {
      this.itemToEdit = item
      this.showEditDialog = true
    },
    handlePageChange (value) {
      this.$emit('pageChanged', value)
      // Scroll back to top.
      this.$vuetify.goTo(`#${this.tableId}`, this.scrollOptions)
    },
    async onRegistrationResent (userId) {
      this.$emit('registrationResent', userId)
    },
    onItemDeleted (deletedItem) {
      this.removeFromSelectedItems(deletedItem.id)
      this.$emit('itemDeleted', deletedItem)
    },
    onItemUpdated (item) {
      this.$emit('itemUpdated', item)
    },
    onItemCreated (item) {
      this.$emit('itemCreated', item)
    },
    async onSuspensionConfirmed (suspension) {
      this.isBusySuspending = true
      this.suspensionValidationErrors = {}
      const payload = {
        isActive: true,
        isIndefinitely: suspension.isIndefinitely,
        until: suspension.isIndefinitely ? null : suspension.until,
        reason: suspension.reason
      }
      try {
        for (let i = 0; i < this.selectedItems.length; i++) {
          const item = this.selectedItems[i]
          payload.userId = item.id
          const newSuspension = await this.createSuspension(payload)
          // Show a message indicating the save succeeded.
          this.showSnackbar({
            role: 'success',
            messages: [this.$t('common.save-success-message', { entityName: this.$t('common.suspension') })],
            duration: 5000
          })
          // Update the user.
          item.suspensionIds.push(newSuspension.id)
          this.onItemUpdated(item)
          this.removeFromSelectedItems(item.id)
        }
        this.showSuspendDialog = false
      } catch (error) {
        console.error(error)
        if (error.response.status === 400 && error.response && error.response.data && error.response.data.errors) {
          this.suspensionValidationErrors = error.response.data.errors
        } else {
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('common.save-failed-message', { entityName: this.$t('common.suspension') })],
            duration: 5000
          })
        }
      } finally {
        this.isBusySuspending = false
      }
    },
    removeFromSelectedItems (id) {
      // Try to find the item among the currently selected items.
      const index = this.selectedItems.findIndex((item) => item.id === id)
      if (index !== -1) {
        // Delete the item from the selected items.
        this.selectedItems.splice(index, 1)
      }
    }
  },
  mounted () {
    this.currentPage = this.pageNumber
  },
  watch: {
    totalSize (newValue, oldValue) {
      if (this.currentPage > this.totalPages && this.currentPage !== 1) {
        console.warn(`Page ${this.currentPage} does not exist, routing back to page 1`)
        this.currentPage = 1
        this.$emit('pageChanged', this.currentPage)
      }
    },
    options: {
      immediate: false,
      handler (newValue) {
        this.$emit('sortingChanged', new Sorting(this.options.sortBy, this.options.sortDesc))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/**
  Tooltips blend in with the background of a hovered row.
  This makes the tooltips a bit darker, so they are easier to see.
 */
.v-tooltip__content {
  background: black !important;
}

.tooltip-icon {
  margin-right: 2px;
}
</style>
