<template>
  <!-- Title -->
  <div>
    <v-row justify="space-around">
      <v-skeleton-loader
        :loading="isLoadingSystem"
        class="mt-2"
        type="chip"
      >
        <h1 class="text-center">
          {{ system?.name }}
        </h1>
      </v-skeleton-loader>
    </v-row>

    <!-- Content -->
    <v-row>
      <v-col cols="12" md="12" lg="6">
        <!-- Events -->
        <v-card>
          <v-system-bar class="py-5">
            <h3>{{ $t('systems.details.events-title') }}</h3>
          </v-system-bar>

          <v-container>
            <v-skeleton-loader
              :loading="isLoadingEvents"
              type="list-item-avatar-two-line, list-item-avatar, list-item-avatar-two-line, list-item-avatar-three-line"
            >
              <v-timeline dense align-top v-if="events.length > 0">
                <transition-group name="events">
                  <EventTimeLineItem
                    v-for="event in events"
                    :key="event.id"
                    :event="event"
                    :showDate="true"
                  />
                </transition-group>
              </v-timeline>

              <div v-else>
                <div class="center-horizontal">
                  <h1>{{ $t('errors.no-data-available') }}</h1>
                </div>
                <div class="center-horizontal">
                  <v-btn
                    fab
                    icon
                    @click="fetchAllData"
                    color="primary"
                    x-large
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-skeleton-loader>
          </v-container>
        </v-card>
      </v-col>

      <v-col cols="12" md="12" lg="6">
        <!-- General information -->
        <v-card>
          <v-system-bar class="py-5">
            <h3>{{ $t('systems.details.general-title') }}</h3>
          </v-system-bar>

          <v-container>
              <v-skeleton-loader
                :loading="isLoadingSystem"
                type="list-item"
                class="small-list-item"
              >
                <v-row>
                  <v-col sm="6">
                    <span class="font-weight-medium mb-0">{{ $t('systems.details.last-used') }}</span>
                  </v-col>
                  <v-col sm="6">
                    <span v-if="latestSession == null">-</span>
                    <DateTimeDisplay
                      v-else
                      :date="latestSession.startTime"
                    />
                  </v-col>
                </v-row>
              </v-skeleton-loader>
              <v-skeleton-loader
                :loading="isLoadingSoftwareVersion"
                type="list-item"
                class="small-list-item"
              >
                <v-row>
                  <v-col sm="6">
                    <span class="font-weight-medium mb-0">{{ $t('systems.details.software') }}</span>
                  </v-col>
                  <v-col sm="6">
                    <SoftwareVersionDisplay :softwareVersion="softwareVersion"/>
                  </v-col>
                </v-row>
              </v-skeleton-loader>
          </v-container>
        </v-card>

        <!-- Sessions -->
        <SessionsTable
          :items="sessions"
          :totalSize="sessionsTotalSize"
          :use-paging="false"
          :loading="isLoadingSessions"
          :headers="sessionTableHeaders"
          :title="$t('systems.details.latest-sessions-table-title')"
          :features="sessionTableFeatures"
          @refreshRequested="fetchAllData"
          class="py-5"
        />

        <!-- ConfigurationBackups -->
        <ConfigurationBackupsTable
          v-if="isAdmin"
          :items="configurationBackups"
          :totalSize="backupsTotalSize"
          :page-size="backupsPageSize"
          :page-number="backupsPageNumber"
          :loading="isLoadingBackups"
          :headers="backupTableHeaders"
          :features="backupTableFeatures"
          :title="$t('configuration-backups.table.title')"
          @pageChanged="backupsPageChanged"
          @sortingChanged="onBackupsSortingChanged"
          @refreshRequested="fetchAllData"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters, mapState } from 'vuex'
import ConfigurationBackupsTable from '@/components/tables/ConfigurationBackupsTable'
import DateTimeDisplay from '@/components/DateTimeDisplay'
import sessionsHelper from '@/components/helpers/sessionsHelper'
import SessionsTable from '@/components/tables/SessionsTable'
import SoftwareVersionDisplay from '@/components/SoftwareVersionDisplay'
import EventTimeLineItem from '@/components/EventTimeLineItem'
import Sorting from '@/models/sorting'
import SystemHub from '@/models/systemHub'

export default {
  components: { ConfigurationBackupsTable, DateTimeDisplay, SessionsTable, SoftwareVersionDisplay, EventTimeLineItem },
  data () {
    return {
      systemHub: null,
      system: null,
      sessions: [],
      events: [],
      configurationBackups: [],
      softwareVersion: null,
      amountOfEventsToDisplay: 25,
      isLoadingEvents: true,
      isLoadingSoftwareVersion: true,
      isLoadingSystem: true,
      isLoadingSessions: true,
      amountOfSessionsToFetch: 5,
      sessionsTotalSize: 0,
      // No features, because this table is only for viewing.
      sessionTableFeatures: [],
      backupsPageSize: 5,
      backupsPageNumber: 1,
      backupsTotalSize: 0,
      backupTableFeatures: ['download'],
      backupSorting: undefined,
      isLoadingBackups: false
    }
  },
  computed: {
    ...mapState('account', ['authenticationToken']),
    ...mapGetters('account', ['isAdmin', 'requiresRefresh']),
    id () {
      const idFromRoute = this.$route.params.systemId
      return parseInt(idFromRoute)
    },
    latestSession () {
      return sessionsHelper.getLatestSession(this.sessions)
    },
    backupTableHeaders () {
      return [
        { text: this.$t('configuration-backups.table.columns.timestamp'), value: 'timestamp', sortable: true },
        { text: this.$t('configuration-backups.table.columns.json-content'), value: 'jsonContent', sortable: false },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ]
    },
    sessionTableHeaders () {
      return [
        { text: this.$t('sessions.table.columns.date'), value: 'startTime', sortable: false },
        { text: this.$t('sessions.table.columns.driver'), value: 'driver', sortable: false },
        { text: this.$t('sessions.table.columns.vehicle'), value: 'vehicle', sortable: false },
        { text: this.$t('sessions.table.columns.duration'), value: 'duration', sortable: false },
        { text: this.$t('sessions.table.columns.quantity-fuelled'), value: 'quantityFuelled', sortable: false }
      ]
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('systems', ['fetchSystem']),
    ...mapActions('configurationBackups', ['fetchConfigurationBackupsBySystemId']),
    ...mapActions('account', ['refreshLogin']),
    async connectToSystemHub () {
      if (this.systemHub === null) {
        this.systemHub = this.createSystemHub()
      }

      try {
        await this.systemHub.start()
      } catch (error) {
        // When setting up the live connection with the systemhub fails, we try to reconnect after 5 seconds.
        setTimeout(this.connectToSystemHub, 5000)
        return
      }
      // We need to subscribe to the specific systems in order to get updates on them.
      await this.subscribeToSystemUpdates(this.id)
    },
    createSystemHub () {
      const systemHub = new SystemHub(this.getAccessTokenForSystemHub)
      systemHub.connection.onreconnected(this.systemHubReconnected)
      systemHub.connection.on('NewStatusReceived', () => { /* Intentionally empty, we don't need this data in this page yet. */ })
      systemHub.connection.on('AllStatusesReceived', () => { /* Intentionally empty, we don't need this data in this page yet. */ })
      systemHub.connection.on('NewEventsReceived', this.eventsReceived)
      return systemHub
    },
    async subscribeToSystemUpdates (systemId) {
      const immediatelySendAllStatuses = false
      await this.systemHub.subscribe([systemId], immediatelySendAllStatuses)
    },
    async getAccessTokenForSystemHub () {
      // Refresh the authentication token if required.
      // The refresh is placed inside this factory in accordance with:
      // https://docs.microsoft.com/en-us/aspnet/core/signalr/authn-and-authz?view=aspnetcore-6.0#bearer-token-authentication.
      if (this.requiresRefresh()) await this.refreshLogin()

      return this.authenticationToken
    },
    async eventsReceived (data) {
      for (let i = 0; i < data.length; i++) {
        const systemEvent = data[i]
        console.log('received event', systemEvent)
        // Remove the last (oldest) event if the event list is full.
        if (this.events.length >= this.amountOfEventsToDisplay) this.events.pop()
        // Add the new event to the beginning of the array.
        this.events.unshift(systemEvent)
      }
    },
    async systemHubReconnected (e) {
      // We need to renew the subscriptions on reconnect,
      // because we don't know if the server still remembers what we were subscribed to before the connection was gone.
      await this.subscribeToSystemUpdates(this.id)
    },
    async backupsPageChanged (newPageNumber) {
      // Update the current page number.
      this.backupsPageNumber = newPageNumber
      // Fetch data with the new page number.
      await this.fetchBackupsDebounced(this.backupsPageNumber, this.backupsPageSize, this.backupSorting)
    },
    async onBackupsSortingChanged (newSorting) {
      this.backupSorting = newSorting
      await this.fetchBackupsDebounced(this.backupsPageNumber, this.backupsPageSize, this.backupSorting)
    },
    fetchBackupsDebounced: debounce(async function (pageNumber, pageSize, sorting) {
      await this.fetchBackupsPage(pageNumber, pageSize, sorting)
    }, 250),
    async fetchBackupsPage (pageNumber, pageSize, sorting) {
      this.isLoadingBackups = true
      try {
        const pagedResponse = await this.fetchConfigurationBackupsBySystemId({ systemId: this.id, pageNumber, pageSize, sorting })
        this.configurationBackups = pagedResponse.items
        this.backupsTotalSize = pagedResponse.totalSize
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
      } finally {
        this.isLoadingBackups = false
      }
    },
    async fetchSessions (systemId) {
      this.isLoadingSessions = true
      try {
        const sorting = new Sorting(['startTime'], [true])
        const sessionsResponse = await axios.get(`systems/${systemId}/sessions`, {
          params: {
            pageNumber: 1,
            pageSize: this.amountOfSessionsToFetch,
            sorting: sorting.toQueryParams()
          }
        })
        this.sessions = sessionsResponse.data.items
        this.sessionsTotalSize = sessionsResponse.data.totalSize
      } finally {
        this.isLoadingSessions = false
      }
    },
    async fetchSoftwareVersion (systemId) {
      this.isLoadingSoftwareVersion = true
      try {
        const versionResponse = await axios.get(`systems/${systemId}/softwareVersion`)
        this.softwareVersion = versionResponse.data === '' ? null : versionResponse.data
      } finally {
        this.isLoadingSoftwareVersion = false
      }
    },
    async fetchEvents (systemId) {
      this.isLoadingEvents = true
      try {
        const sorting = new Sorting(['timeStamp'], [true])
        const eventsResponse = await axios.get(`systems/${systemId}/systemEvents`, {
          params: {
            pageNumber: 1,
            pageSize: this.amountOfEventsToDisplay,
            sorting: sorting.toQueryParams()
          }
        })
        this.events = eventsResponse.data.items
      } finally {
        this.isLoadingEvents = false
      }
    },
    async fetchSystemWithLoader (systemId) {
      this.isLoadingSystem = true
      try {
        const systemResponse = await this.fetchSystem(systemId)
        this.system = systemResponse.data
      } finally {
        this.isLoadingSystem = false
      }
    },
    async fetchAllData () {
      try {
        // Retrieve all required data.
        // Note: Not fetching all software versions, if the sessions table would display those, they would also need to be fetched.
        await Promise.all([
          this.fetchEvents(this.id),
          this.fetchSystemWithLoader(this.id),
          this.fetchSoftwareVersion(this.id),
          this.fetchSessions(this.id),
          this.isAdmin ? this.fetchBackupsDebounced(this.backupsPageNumber, this.backupsPageSize, this.backupSorting) : Promise.resolve()
        ])
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
      }
    }
  },
  async mounted () {
    await Promise.all([
      this.fetchAllData(),
      this.connectToSystemHub()
    ])
  },
  async beforeDestroy () {
    if (!this.systemHub) return
    await this.systemHub.stop()
  }
}
</script>

<style lang="scss" scoped>
.center-horizontal {
  display: flex;
  justify-content: center;
}

// Vuetify skeleton loader list-item is a little bit too big (48px), so we make it smaller with this 'deep' override.
// Inspired by https://stackoverflow.com/a/61172131/12924241.
.small-list-item {
  ::v-deep .v-skeleton-loader__list-item {
    height: 30px;
  }
}

// General information about Vue transition groups:
// Entering: when a new event is added to (usually top of) the timeline.
// Moving: When an event is moved down in the timeline, usually because another event was added in the top of the timeline.
// Leaving: When an event is removed from (usually bottom of) the timeline.

// Animations for moving events across the timeline are the same for entering, leaving and moving.
.events-move,
.events-enter-active,
.events-leave-active {
  transition-duration: 300ms;
  // Providing the specific properties instead of 'all' can give a significant performance boost.
  transition-property: transform, opacity;
  transition-timing-function: ease;
}

// Class events-enter-from was not working in this scenario, somehow only events-enter worked.
// Still keeping events-enter-from though, to stay compliant with the Vue documentation (https://vuejs.org/guide/built-ins/transition-group#enter-leave-transitions).
.events-enter-from,
.events-enter {
  // Fades the new event in.
  opacity: 0;
  // Slides the new event into the timeline from the top.
  transform: translateY(-50px);
}

.events-leave-to {
  // Fades the event out.
  opacity: 0;
}

.events-leave-active {
  // Ensures leaving events are taken out of layout flow
  // so that moving animations can be calculated correctly (https://vuejs.org/guide/built-ins/transition-group#move-transitions).
  position: absolute;
  // Somehow width 100% is needed to stop the event timeline item from shrinking during the leave animations.
  width: 100%;
}
</style>
