<template>
  <v-dialog v-model="showDialog" max-width="700px">
    <ProgressDialog v-model="isUploading" :progress="progress" color="teal">
      <template #title>
        {{ $t('upload-dialog.progress-title') }}<v-spacer></v-spacer>({{ itemsUploadedCount }} / {{ itemsToUploadCount }})
      </template>
    </ProgressDialog>

    <v-card>
      <v-card-title class="headline">
        {{ $t('upload-dialog.title') }}
      </v-card-title>

      <v-card-text>
        <v-alert border="right" colored-border type="info" elevation="2" class="ma-4" icon="mdi-information">
          {{ $t('upload-dialog.help-text') }}
        </v-alert>
        <ErrorsDisplay :errors="errorsToDisplay" class="ma-4" />

        <v-file-input
          v-model="file"
          :label="$t('upload-dialog.file-input-label')"
          accept=".json"
          truncate-length="25"
          clearable
          show-size
          :disabled="isUploading"
        >
          <template #selection="{ text, file }">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" label>
                  {{ text }}
                </v-chip>
              </template>
              <span>{{ file.name }}</span>
            </v-tooltip>
          </template>
        </v-file-input>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="showDialog = false" :loading="isUploading">
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn color="primary" @click="uploadClicked" :loading="isUploading" :disabled="this.file === null">
          {{ $t('upload-dialog.upload-button') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import ErrorsDisplay from '@/components/ErrorsDisplay'
import ProgressDialog from '@/components/dialogs/ProgressDialog'

export default {
  components: { ErrorsDisplay, ProgressDialog },
  props: {
    /**
     * Indicates whether this dialog should be shown right now.
     * Use the @input event to listen for the dialog to be closed.
     */
    value: { type: Boolean, required: true }
  },
  /**
   * eventsUploaded: When system events were uploaded.
   * sessionUploaded: When a single system event is uploaded.
   * uploadFinished: When all items are done uploading.
   */
  emits: ['input', 'eventsUploaded', 'sessionUploaded', 'uploadFinished'],
  data () {
    return {
      isUploading: false,
      file: null,
      itemsToUploadCount: 0,
      itemsUploadedCount: 0,
      errors: [],
      maximumAmountOfDisplayedErrors: 5
    }
  },
  computed: {
    showDialog: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
        this.errors = []
        this.file = null
      }
    },
    progress () {
      return this.itemsToUploadCount === 0 || this.itemsUploadedCount === 0
        ? 0
        : Math.ceil((this.itemsUploadedCount / this.itemsToUploadCount) * 100)
    },
    errorsToDisplay () {
      return this.errors.length > this.maximumAmountOfDisplayedErrors ? this.errors.slice(0, this.maximumAmountOfDisplayedErrors) : this.errors
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    async uploadClicked () {
      this.errors = []

      const json = await this.readFileAsync(this.file)
      const parsedJson = JSON.parse(json)
      console.log(`Uploading ${this.file.name} with contract version ${parsedJson.ContractVersion} and system upload id ${parsedJson.SystemUploadId}, containing ${parsedJson.Sessions.length} sessions and ${parsedJson.SystemEvents.length} system events`)

      this.itemsUploadedCount = 0
      this.itemsToUploadCount = parsedJson.Sessions.length + parsedJson.SystemEvents.length
      this.isUploading = true
      try {
        // Upload system events.
        await this.uploadSystemEvents(parsedJson.SystemEvents, parsedJson.SystemUploadId)
        // Upload sessions.
        await this.uploadSessions(parsedJson.Sessions)
        // Close the dialog.
        this.showDialog = false
      } catch (error) {
        if (error.response?.status === 404 && Array.isArray(error.reponse.data.errors)) {
          this.errors = error.response.data.errors
        } else if (error.response?.status === 400 && error.response.data?.errors) {
          this.errors = this.validationErrorsToArray(error.response.data.errors)
        } else {
          this.errors = [error]
        }
      } finally {
        console.log(`${this.itemsUploadedCount}/${this.itemsToUploadCount} items uploaded`)
        if (this.itemsUploadedCount === this.itemsToUploadCount) {
          // Show a message indicating the upload succeeded.
          this.showSnackbar({
            role: 'success',
            messages: [this.$t('upload-dialog.upload-success-message')],
            duration: 5000
          })
          // If all items were uploaded succesfully, we can clear the file input.
          this.file = null
        } else {
          // Show a message indicating the upload failed.
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('upload-dialog.upload-failed-message')],
            duration: 5000
          })
        }

        this.isUploading = false
        this.$emit('uploadFinished', this.itemsUploadedCount)
      }
    },
    /**
     * Reads the given file as text.
     * @param {File} file The file to read.
     * @returns {Promise<string>} The file content as text.
     */
    async readFileAsync (file) {
      return await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = () => reject(new Error(this.$t('errors.loading-file-failed')))
        reader.readAsText(file)
      })
    },
    // TODO: If uploading the chunks one by one turns out to be a performance bottleneck,
    // consider uploading the chunks in parallel.
    async uploadSystemEvents (systemEvents, systemUploadId) {
      if (!systemEvents || systemEvents.length === 0) return

      // We don't want to send one giant request with all the system events, so we send them in smaller chunks.
      const maxChunkSize = 50
      let currentIndex = 0
      while (currentIndex < systemEvents.length) {
        const amountOfEventsLeftToUpload = systemEvents.length - currentIndex
        // Making sure the chunk size is not larger than the amount of events left to upload.
        // For example, if there are 30 events left to upload, we can't send a chunk of 50.
        const chunkSize = Math.min(maxChunkSize, amountOfEventsLeftToUpload)
        const eventsChunk = systemEvents.slice(currentIndex, chunkSize)
        const request = {
          systemUploadId: systemUploadId,
          events: eventsChunk
        }

        const response = await axios.post('systemEvents', request)
        this.itemsUploadedCount += chunkSize
        // Tell parent component new events were uploaded.
        this.$emit('eventsUploaded', response.data)

        currentIndex += chunkSize
      }
    },
    /**
     * Uploads all sessions in the given array in parallel.
     * @param {Array} sessions The sessions to upload.
     * @returns {Promise} A promise that resolves when all sessions are uploaded.
     */
    async uploadSessions (sessions) {
      const uploads = []
      for (let i = 0; i < sessions.length; i++) {
        const session = sessions[i]
        const uploadPromise = this.uploadSession(session)
        uploads.push(uploadPromise)
      }
      await Promise.all(uploads)
    },
    async uploadSession (session) {
      try {
        const response = await axios.post('sessions', session)
        this.itemsUploadedCount++
        // Tell parent component a new session was uploaded.
        this.$emit('sessionUploaded', response.data)
      } catch (error) {
        // Ignore 409 (conflict) responses, they mean the session already exists.
        if (error.response && error.response.status === 409) {
          this.itemsUploadedCount++
        } else {
          throw error
        }
      }
    },
    validationErrorsToArray (validationErrorObject) {
      return Object.entries(validationErrorObject).reduce((result, [key, errors]) => {
        return result.concat(errors)
      }, [])
    }
  }
}
</script>
