<template>
  <div>
    <!-- Details dialog -->
    <SessionDetailsDialog
      v-if="canViewDetails"
      v-model="showDetailsDialog"
      :session="itemToViewDetails"
      @input="(newValue) => { showDetailsDialog = newValue }"
    />

    <!-- Edit dialog -->
    <EditSessionDialog
      v-if="canEdit"
      v-model="showEditDialog"
      :session="itemToEdit"
      @input="(newValue) => { showEditDialog = newValue }"
      @itemUpdated="onItemUpdated"
    />

    <!-- Upload dialog -->
    <UploadLocalDataDialog
      v-if="canUpload"
      v-model="showUploadDialog"
      @input="(newValue) => { showUploadDialog = newValue }"
      @eventsUploaded="onEventsUploaded"
      @sessionUploaded="onSessionUploaded"
      @uploadFinished="onUploadFinished"
    />

    <!-- Archive dialog -->
    <ConfirmDialog
      v-if="canArchive"
      v-model="showArchiveDialog"
      @input="(newValue) => { showArchiveDialog = newValue }"
      :title="$tc('sessions.archive-dialog.title', selectedItems.length)"
      :warning="$t('sessions.archive-dialog.warning')"
      @confirmed="archiveConfirmed"
      :isBusy="isBusyArchiving"
    />

    <!-- Unarchive dialog -->
    <ConfirmDialog
      v-if="canArchive"
      v-model="showUnarchiveDialog"
      @input="(newValue) => { showUnarchiveDialog = newValue }"
      :title="$tc('sessions.unarchive-dialog.title', selectedItems.length)"
      :warning="$t('sessions.unarchive-dialog.warning')"
      @confirmed="unarchiveConfirmed"
      :isBusy="isBusyArchiving"
    />

    <!-- Delete dialog -->
    <DeleteItemsDialog
      v-if="canDelete"
      v-model="showDeleteDialog"
      @input="(newValue) => { showDeleteDialog = newValue }"
      :warning="$t('sessions.delete-warning')"
      :items="selectedItems"
      deleteUrl="sessions"
      :entityName="$t('common.session').toLowerCase()"
      :entityNamePlural="$t('common.sessions').toLowerCase()"
      @itemDeleted="onItemDeleted"
    />

    <v-data-table
      v-model="selectedItems"
      :items="items"
      :server-items-length="totalSize"
      :options.sync="options"
      :multi-sort="true"
      :headers="computedHeaders"
      :loading="loading"
      :show-expand="showExpand"
      :expanded.sync="expandedItems"
      :show-select="canArchive"
      single-expand
      disable-pagination
      hide-default-footer
      class="elevation-2"
      :id="id"
    >
      <!-- Title bar -->
      <template v-if="title != null" v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>

          <!-- Upload button -->
          <v-btn
            v-if="canUpload"
            class="mx-1 teal white--text"
            @click="showUploadDialog = true"
          >
            <v-icon :left="$vuetify.breakpoint.mdAndUp">
              mdi-cloud-upload
            </v-icon>
            <div v-if="$vuetify.breakpoint.mdAndUp">
              {{ $t('sessions.table.upload-button') }}
            </div>
          </v-btn>

          <!-- Archive/Unarchive buttons -->
          <v-menu
            v-if="canArchive"
            open-on-click
            bottom
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-1 orange darken-2 white--text"
                :disabled="selectedItems.length <= 0"
              >
                <v-icon :left="$vuetify.breakpoint.mdAndUp">
                  mdi-archive
                </v-icon>
                <div v-if="$vuetify.breakpoint.mdAndUp">
                  {{ $t('sessions.table.archive-button') }}
                </div>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="showArchiveDialog = true" class="orange darken-2">
                <v-list-item-icon>
                  <v-icon class="white--text">mdi-archive-arrow-down</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="white--text">
                  {{ $t('sessions.table.archive-button') }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="showUnarchiveDialog = true" class="secondary">
                <v-list-item-icon>
                  <v-icon class="white--text">mdi-archive-arrow-up</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="white--text">
                  {{ $t('sessions.table.unarchive-button') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <!-- Delete button -->
          <v-btn
            v-if="canDelete"
            class="mx-1 white--text"
            color="error"
            @click="showDeleteDialog = true"
            :disabled="selectedItems.length === 0"
          >
            <v-icon :left="$vuetify.breakpoint.mdAndUp">
              mdi-delete-forever
            </v-icon>
            <div v-if="$vuetify.breakpoint.mdAndUp">
              {{ $t('common.buttons.delete') }}
            </div>
          </v-btn>
        </v-toolbar>
      </template>

      <!-- Empty display -->
      <template v-slot:no-data>
        <h3 class="mt-3"> {{$t('errors.no-data-available')}} </h3>
        <v-btn
          icon
          large
          class="my-2"
          color="primary"
          @click="$emit('refreshRequested')"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>

      <!-- Custom cells -->
      <template v-slot:[`item.indications`]="{item}">
        <v-icon v-if="item.hasError" color="error" dense>
          mdi-alert-circle
        </v-icon>
        <v-icon v-if="item.hasWarning" color="warning" dense>
          mdi-alert
        </v-icon>
        <v-tooltip right v-if="item.isArchived">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="orange"
              dense
              v-bind="attrs"
              v-on="on"
            >
              mdi-archive
            </v-icon>
          </template>
          <span>{{ $t('sessions.table.archived-tooltip') }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.startTime`]="{value}">
        <DateTimeDisplay :date="value"/>
      </template>
      <template v-slot:[`item.system`]="{item}">
        <SystemLinkDisplay :systemId="item.systemId"/>
      </template>
      <template v-slot:[`item.driver`]="{item}">
        <DriverLinkDisplay :driverId="item.driverId"/>
      </template>
      <template v-slot:[`item.vehicle`]="{item}">
        <VehicleLinkDisplay :vehicleId="item.vehicleId"/>
      </template>
      <template v-slot:[`item.duration`]="{item}">
        <DurationDisplay
          :startTime="item.startTime"
          :endTime="item.endTime"
        />
      </template>
      <template v-slot:[`item.quantityFuelled`]="{item}">
        <FuelQuantityDisplay :quantity="item.quantityFuelled"
                             :unit="getUnitBySystemId(item.systemId)"/>
      </template>

      <!-- Expanded -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <SessionTableExpansion :session="item"/>
        </td>
      </template>

      <!-- Custom actions -->
      <template v-slot:[`item.actions`]="{item}">
        <v-btn
          v-if="canViewDetails"
          icon
          @click="openDetailsDialog(item)"
          color="info">
          <v-icon>
            mdi-open-in-new
          </v-icon>
        </v-btn>
        <v-btn
          v-if="canEdit"
          icon
          @click="editClicked(item)"
          color="orange">
          <v-icon>
            mdi-square-edit-outline
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-pagination
      v-if="usePaging && totalSize > 0"
      v-model="currentPage"
      :length="totalPages"
      total-visible="7"
      next-icon="mdi-menu-right"
      prev-icon="mdi-menu-left"
      @input="handlePageChange"
      class="mt-2"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import DateTimeDisplay from '@/components/DateTimeDisplay'
import DeleteItemsDialog from '@/components/dialogs/DeleteItemsDialog'
import DriverLinkDisplay from '@/components/DriverLinkDisplay'
import DurationDisplay from '@/components/DurationDisplay'
import EditSessionDialog from '@/components/dialogs/EditSessionDialog'
import FuelQuantityDisplay from '@/components/FuelQuantityDisplay'
import SessionDetailsDialog from '@/components/dialogs/SessionDetailsDialog'
import SessionTableExpansion from '@/components/tables/SessionTableExpansion'
import Sorting from '@/models/sorting'
import SystemLinkDisplay from '@/components/SystemLinkDisplay'
import UploadLocalDataDialog from '@/components/dialogs/UploadLocalDataDialog.vue'
import VehicleLinkDisplay from '@/components/VehicleLinkDisplay'

export default {
  components: {
    ConfirmDialog,
    DateTimeDisplay,
    DeleteItemsDialog,
    DriverLinkDisplay,
    DurationDisplay,
    EditSessionDialog,
    FuelQuantityDisplay,
    SessionDetailsDialog,
    SessionTableExpansion,
    SystemLinkDisplay,
    UploadLocalDataDialog,
    VehicleLinkDisplay
  },
  emits: ['itemUploaded', 'itemUpdated', 'itemDeleted', 'pageChanged', 'sortingChanged', 'refreshRequested'],
  props: {
    /**
     * The items to display.
     */
    items: { type: Array, required: true },
    showExpand: { type: Boolean, default: true },
    title: { type: String, default: null },
    totalSize: { type: Number, default: 0 },
    /**
     * The headers and columns to display.
     */
    headers: { type: Array },
    /**
     * The features to enable for this table.
     * Supply with an array of the desired features ('update', 'archive', 'upload', 'delete', 'details').
     */
    features: { type: Array, default: () => ['update', 'archive', 'upload', 'delete', 'details'] },
    /**
     * True will enable pagination buttons/inputs, false will disable them. Defaults to true.
     */
    usePaging: { type: Boolean, default: true },
    pageSize: { type: Number, default: null },
    pageNumber: { type: Number, default: null },
    loading: { type: Boolean, default: false },
    id: { type: String, default: 'sessions-table' }
  },
  data () {
    return {
      currentPage: 0,
      expandedItems: [],
      showEditDialog: false,
      itemToEdit: {},
      itemToViewDetails: {},
      selectedItems: [],
      showUploadDialog: false,
      showArchiveDialog: false,
      showUnarchiveDialog: false,
      showDeleteDialog: false,
      showDetailsDialog: false,
      isBusyArchiving: false,
      options: {}
    }
  },
  computed: {
    ...mapGetters('account', ['canUploadSessions']),
    ...mapGetters('systems', ['getSystemById']),
    ...mapGetters('fuelTypes', ['getFuelTypeByValue', 'getPreferredUnitByFuelType']),
    totalPages () {
      return this.totalSize === 0 || this.pageSize === 0 ? 0 : Math.ceil(this.totalSize / this.pageSize)
    },
    canEdit () {
      return this.features && this.features.includes('update')
    },
    canArchive () {
      return this.features && this.features.includes('archive')
    },
    canUpload () {
      return this.features && this.features.includes('upload') && this.canUploadSessions
    },
    canDelete () {
      return this.features && this.features.includes('delete')
    },
    canViewDetails () {
      return this.features && this.features.includes('details')
    },
    computedHeaders () {
      // Both the injected (prop) and default headers must be wrapped in a computed
      // to ensure translations don't require a page refresh.
      return this.headers && this.headers.length > 0 ? this.headers : this.defaultHeaders
    },
    defaultHeaders () {
      return [
        { text: '', value: 'indications', sortable: false },
        { text: this.$t('sessions.table.columns.date'), value: 'startTime' },
        { text: this.$t('sessions.table.columns.system'), value: 'system' },
        { text: this.$t('sessions.table.columns.driver'), value: 'driver' },
        { text: this.$t('sessions.table.columns.vehicle'), value: 'vehicle' },
        { text: this.$t('sessions.table.columns.duration'), value: 'duration' },
        { text: this.$t('sessions.table.columns.quantity-fuelled'), value: 'quantityFuelled' },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ]
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    getUnitBySystemId (systemId) {
      const system = this.getSystemById(systemId)
      const fuelType = this.getFuelTypeByValue(system.fuelType)
      return this.getPreferredUnitByFuelType(fuelType)
    },
    editClicked (item) {
      this.itemToEdit = item
      this.showEditDialog = true
    },
    openDetailsDialog (item) {
      this.itemToViewDetails = item
      this.showDetailsDialog = true
    },
    handlePageChange (value) {
      this.$emit('pageChanged', value)
    },
    onItemUpdated (item) {
      this.$emit('itemUpdated', item)
    },
    onItemUploaded (item) {
      this.$emit('itemUploaded', item)
    },
    onSessionUploaded (item) {
      this.$emit('sessionUploaded', item)
    },
    onEventsUploaded (data) {
      this.$emit('eventsUploaded', data)
    },
    onUploadFinished (amountUploaded) {
      this.$emit('uploadFinished', amountUploaded)
    },
    onItemDeleted (deletedItem) {
      this.removeFromSelectedItems(deletedItem.id)
      this.$emit('itemDeleted', deletedItem)
    },
    async unarchiveConfirmed () {
      await this.archiveOrUnarchive(false)
    },
    async archiveConfirmed () {
      await this.archiveOrUnarchive(true)
    },
    async archiveOrUnarchive (shouldArchive) {
      this.isBusyArchiving = true

      const patchDocument = [
        {
          op: 'replace',
          path: '/isArchived',
          value: shouldArchive
        }
      ]
      try {
        for (var i = this.selectedItems.length - 1; i >= 0; i--) {
          const sessionId = this.selectedItems[i].id
          // Archive/unarchive an item.
          const response = await axios.patch(`sessions/${sessionId}`, patchDocument)
          // Unselect the item.
          this.removeFromSelectedItems(sessionId)
          // Tell the parent which item was updated.
          this.$emit('itemUpdated', response.data)
        }

        this.showUnarchiveDialog = false
        this.showArchiveDialog = false
      } catch (error) {
        console.error(error)
        // No need to check for validation errors here, because there is no validation for IsArchived.
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('sessions.archiving-failed')],
          duration: 5000
        })
      } finally {
        this.isBusyArchiving = false
        // Trigger a page refresh, because depending on filtering, archived/unarchived sessions should be shown.
        this.$emit('pageChanged', this.pageNumber)
      }
    },
    removeFromSelectedItems (id) {
      // Try to find the item among the currently selected items.
      const index = this.selectedItems.findIndex((item) => item.id === id)
      if (index !== -1) {
        // Delete the item from the selected items.
        this.selectedItems.splice(index, 1)
      }
    }
  },
  mounted () {
    this.currentPage = this.pageNumber
  },
  watch: {
    totalSize (newValue, oldValue) {
      if (this.currentPage > this.totalPages && this.currentPage !== 1) {
        console.warn(`Page ${this.currentPage} does not exist, routing back to page 1`)
        this.currentPage = 1
        this.$emit('pageChanged', this.currentPage)
      }
    },
    options: {
      immediate: false,
      handler (newValue) {
        this.$emit('sortingChanged', new Sorting(this.options.sortBy, this.options.sortDesc))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/**
  Tooltips blend in with the background of a hovered row.
  This makes the tooltips a bit darker, so they are easier to see.
 */
.v-tooltip__content {
  background: black !important;
}

/**
  The archive menu has some top and bottom padding (above and below the buttons).
  This removes that padding.
 */
.v-sheet.v-list:not(.v-sheet--outlined) {
  padding: 0;
}
</style>
